<template>
  <div>
    <div class="flex flex-row justify-end mb-8">
        <button class="sk-btn sk-btn-primary rounded-lg" @click="handleCreate">
            {{ $t('apps.campaigns.form.createButton') }}
        </button>
    </div>
    <DataTable
        :config="datatableConfig"
        :elements="scopes"
        :stripe="true"
        :draggable="false"
        :sst="true"
        :pagination="pagination"
        @filter="handleFilter"
        @action="handleAction"
    />
  </div>
</template>

<script>

import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import loader from "@/modules/Shared/Mixins/loader";
import AuthorizationService from "@/modules/Admin/Services/AuthorizationService";
import PaginationService from "@/modules/Shared/Services/PaginationService";

export default {
  name: "AppFeaturesPage",
  components: {DataTable},
  mixins: [loader],
  data() {
    return {
      scopes: [],
      datatableConfig: [
          { 'field' : 'object_id', head : this.$t('shops.id'), 'searchable' : false, 'sortable' : false},
          { 'field' : 'name', head : this.$t('shops.name'), 'searchable' : true, 'sortable' : false},
          { 'field' : 'features', head : this.$t('admin.authorization.features'), 'searchable' : true, 'sortable' : false},
          { 'field' : 'actions', 'type': 'actions', head : this.$t("geofences.geofences-messages.actions"), 'searchable' : false, 'sortable' : false }
      ],
      baseFilters: [
          { field: 'scope_type', value: 'application' }
      ],
      pagination: {},
    }
  },
  async created() {
      await this.getScopes({
          filters: [...this.baseFilters],
          page: 1
      })
  },
  methods: {
      async getScopes(params) {
          await this.load(async () => {
              const scopes = await AuthorizationService.getScopes(PaginationService.parseParameters(params))
              this.scopes =  this.parseScopes(scopes.data)
              this.pagination = {
                  per_page: scopes.per_page,
                  total: scopes.total
              }
          })
      },
      async handleFilter(params) {
          const filters = [
              ...this.baseFilters,
              ...params.filters.map(filter => {
                  return {
                      field: filter.field === 'features' ? 'feature_name' : filter.field,
                      value: filter.value
                  }
              })
          ]
          await this.getScopes(
              {
                  filters: filters,
                  page: params.page
              }
          )
      },
      handleCreate() {
          this.$router.push({ name: 'admin-app-scope-create' });
      },
      handleAction(type, uuid){
          switch(type) {
              case 'edit':
                  this.$router.push({
                      name: 'admin-app-scope-edit',
                      params: {
                          'id': uuid,
                      }
                  });
                  break;
          }
      },
      parseScopes(scopes) {
          return scopes.map(scope => ({
              uuid: scope.id,
              ...scope,
              features: scope.enabled_features.map(feature => feature.name)
                  .sort()
                  .join(', '),
              actions: [ 'edit' ]
          }))
      }
  }
};
</script>
